<template>
  <div
    class="relative font-title min-h-16 flex items-center rounded-sm shadow px-4 gap-4 bg-white"
    :class="{
      'text-primary': props.type === 'info' || props.type === 'success',
      'text-red-desaturated': props.type === 'danger'
    }"
  >
    <div
      class="absolute left-2 top-2 bottom-2 bg-current rounded-full w-1"
    ></div>
    <div
      v-if="icon"
      class="h-16 w-12 -mr-2 text-3xl flex justify-center items-center"
    >
      <Icon :path="icon" />
    </div>
    <div
      class="flex-1 flex flex-col justify-center items-start pt-[0.3rem] pb-[0.4rem] text-neutral"
    >
      <div class="text-base md:text-lg">
        {{ props.title }}
      </div>
      <div
        v-if="props.message"
        class="w-[calc(100%-3rem)] text-xs whitespace-pre-wrap break-words max-h-24 overflow-y-auto"
      >
        {{ props.message }}
      </div>
    </div>
    <AppButton
      v-if="props.actionLabel"
      class="variant-generic [--button-color:currentColor] text-sm md:text-regular h-[40px] px-2 flex justify-center items-center"
      @click="emit('action')"
    >
      {{ props.actionLabel }}
    </AppButton>
    <button
      v-if="props.closable"
      class="text-neutral-lighter h-12 w-12 min-w-12 text-3xl flex justify-center items-center -mr-4"
      type="button"
      @click="emit('close')"
    >
      <Icon :path="mdiClose" />
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  mdiCheckCircleOutline,
  mdiClose,
  mdiCloseCircleOutline,
  mdiInformationOutline
} from '@mdi/js';
import type { PropType } from 'vue';

const emit = defineEmits(['close', 'action']);

const props = defineProps({
  title: {
    type: String,
    default: 'Error'
  },
  message: {
    type: String
  },
  type: {
    type: String as PropType<'success' | 'danger' | 'info'>
  },
  icon: {
    type: String
  },
  closable: {
    type: Boolean,
    default: true
  },
  actionLabel: {
    type: String
  },
  time: {
    type: Number,
    default: 30
  }
});

const defaultIcons = {
  success: mdiCheckCircleOutline,
  danger: mdiCloseCircleOutline,
  info: mdiInformationOutline
};

const icon = computed(() => {
  if (props.type && defaultIcons[props.type] && !props.icon) {
    return defaultIcons[props.type];
  }
  return props.icon;
});

onMounted(() => {
  if (props.time) {
    setTimeout(() => emit('close'), props.time * 1000);
  }
});
</script>
